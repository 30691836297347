<template>
    <div>
        <div class="login" style="margin-top:50px;">
            <van-button type="info" size="large" @click="onSubmit">确认绑定</van-button>
            <div style="text-align:center;margin-top:50px;">
                <img src="@/assets/img/qrcode.jpg" width="50%" alt="">
                <div class="codeClass">请先长按图片，关注此二维码可接收推送消息</div>
            </div>
            
        </div>
    </div>

</template>

<script>
    import { Toast} from 'vant';
    export default {
        name: 'Binding',
        data() {
            return {
                account:'',
                password:''
            }
        },
        methods:{
            onSubmit() {
                this.$api.setMobileOpenId({id:this.$store.state.bindId,openId:localStorage.openId}).then(res => {
                    if(res.data.code == 0)this.$router.push({name:'BindingSuccess'});
                })
            },
        },
       
        created(){
            this.$store.commit('setPageTitle', "绑定管理员");
        }
    }
</script>

<style lang="scss">
.codeClass{
    font-size: 24px;color: red;
}
   
</style>